@import url('https://fonts.googleapis.com/css?family=Yeseva+One');
.App {
  width: 100vw;
  height: max-content;
  background: linear-gradient(
    to right,
    #625f5f25,
    #a49c9d
  );
  padding: 2%;
}

.acrylic-sports-container {
  display: flex;
  justify-content: center;
}
.acrylic-sports-title {
  width: max-content;

    font-family: "Yeseva One", cursive;
    text-transform: capitalize;
    text-align: center;
    font-size: 35px;
    color: rgb(25, 49, 49);
    background-color: rgb(27, 25, 25);
    text-shadow: rgb(222, 115, 115) 2px 2px 2px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Replace with your desired text color */
  font-size: 48px; /* Replace with your desired font size */
  /* Replace with your desired background color */
  padding: 10px;
  letter-spacing: 10px;
  word-spacing: 4%;
  margin-top: .5%;
}

.image-stack {
  display: flex;
  justify-content:space-between;
  position: relative;
  
}

.stacked-card {
  
  width: max-content;
  height: 100%;
  margin: 10px; /* Adjust the margin as needed */
  position: relative;
}

.stacked-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form{

  padding: 10px;
 width: max-content;
 margin: 0 auto 0 auto;
}
.form-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.form-item-label {
  font-weight: bold;
}

.quantity-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.quantity-label {
  margin-right: 10px;
}

.quantity-buttons {
  display: flex;
  align-items: center;
  margin: 2%;
}

.quantity-button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 2%;
}

.quantity-button:hover {
  background-color: #e0e0e0;
}

.input-container {
  margin-bottom: 16px;
}

.textarea-container {
  margin-bottom: 16px;
}

.submit-button {
  width: 100%;
}
.form-div{
  display: flex;
  margin: 10px;
}